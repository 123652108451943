import useEmployeeActions from "./useEmployeeActions"
import useEmployeeActivationActions from "./useEmployeeActivationActions"
import useEmployeeDeactivationActions from "./useEmployeeDeactivationActions"
import useEmployerActions from "./useEmployerActions"
import useFileActions from "./useFileActions"
import usePayrollActions from "./usePayrollActions"
import {userHasAuthorization} from "../util/role";
import {useAppSelector} from "../redux/hooks";

const useInitialDataFetch = () => {
    const { getPendingEmployees: getEmployeesPendingActivation } = useEmployeeActivationActions()
    const { getPendingEmployees: getEmployeesPendingDeactivation } = useEmployeeDeactivationActions()
    const { getEmployer } = useEmployerActions()
    const { getEmployees } = useEmployeeActions()
    const { getPayrollData } = usePayrollActions()
    const { getFiles } = useFileActions()
    const user = useAppSelector(state => state.auth.authenticatedUser)
    const currentEmployerId = useAppSelector(state => state.auth.currentEmployerId)

    const fetchData = () => {
        getEmployer()
        getEmployees()
        getFiles()

        if (!user || !currentEmployerId) {
            return
        }

        if (userHasAuthorization(user, currentEmployerId, role => role.allowManageEmployeeActivations)) {
            getEmployeesPendingActivation()
            getEmployeesPendingDeactivation()
        }

        if (userHasAuthorization(user, currentEmployerId, role => role.allowManagePayroll)) {
            getPayrollData()
        }
    }

    return {
        fetchData
    }
}

export default useInitialDataFetch
