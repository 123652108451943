import {useEffect, useState} from 'react'
import {createUseStyles} from 'react-jss'
import Modal from './Modal'
import {
  CreateEmployerPortalUserModel,
  EmployerPortalUser,
  PortalRoleModel,
  UpdateEmployerPortalUserModel
} from '../redux/types'
import InputField from './InputField'
import Switch from './Switch'
import {useTranslation} from 'react-i18next'

interface Props {
  initialValue: EmployerPortalUser | undefined
  portalRoles: PortalRoleModel[]
  onCreate: (createEmployerPortalUserModel: CreateEmployerPortalUserModel) => void
  onUpdate: (updateEmployerPortalUserModel: UpdateEmployerPortalUserModel) => void
  onCancelClick: () => void
  visible: boolean
}


const EditEmployerPortalUserModal = ({
                                       initialValue,
                                       portalRoles,
                                       onCreate,
                                       onUpdate,
                                       onCancelClick,
                                       visible
                                     }: Props) => {
  const styles = useStyles()
  const {t} = useTranslation(['common', 'portalUsers']);
  const [identificationNumber, setIdentificationNumber] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [portalRoleIds, setPortalRoleIds] = useState<string[]>([])
  const [isContactTechnical, setIsContactTechnical] = useState<boolean>(false)
  const [isContactFinance, setIsContactFinance] = useState<boolean>(false)
  const [isContactPayroll, setIsContactPayroll] = useState<boolean>(false)

  const isEditing = !!initialValue
  const title = isEditing ? t('editPortalUser', {ns: 'portalUsers'}) : t('addPortalUser', {ns: 'portalUsers'})

  const setState = (employerPortalUser: EmployerPortalUser | undefined) => {
    if (employerPortalUser) {
      setIdentificationNumber(employerPortalUser.identificationNumber)
      setEmail(employerPortalUser.email)
      setPortalRoleIds(employerPortalUser.portalRoles.map(x => x.id))
      setIsContactTechnical(employerPortalUser.isContactTechnical)
      setIsContactFinance(employerPortalUser.isContactFinance)
      setIsContactPayroll(employerPortalUser.isContactPayroll)
    } else {
      setIdentificationNumber('')
      setEmail('')
      setPortalRoleIds([])
      setIsContactTechnical(false)
      setIsContactFinance(false)
      setIsContactPayroll(false)
    }
  }

  useEffect(() => {
    setState(initialValue)
  }, [initialValue])

  const isSaveable = () => {
    return isEditing || (identificationNumber.length > 0 && email.length > 0)
  }

  const handleOnSave = () => {
    if (isEditing) {
      onUpdate({
        portalRoleIds: portalRoleIds,
        isContactFinance: isContactFinance,
        isContactTechnical: isContactTechnical,
        isContactPayroll: isContactPayroll
      } as UpdateEmployerPortalUserModel)
    } else {
      onCreate({
        identificationNumber: identificationNumber,
        email: email,
        portalRoleIds: portalRoleIds,
        isContactFinance: isContactFinance,
        isContactTechnical: isContactTechnical,
        isContactPayroll: isContactPayroll
      } as CreateEmployerPortalUserModel)
    }

    setState(undefined)
  }

  const handleOnCancel = () => {
    onCancelClick()
    setState(undefined)
  }

  const handleRoleChange = (portalRoleModel: PortalRoleModel, value: boolean) => {
    if (value) {
      setPortalRoleIds([...portalRoleIds, portalRoleModel.id])
    } else {
      setPortalRoleIds(portalRoleIds.filter(x => x !== portalRoleModel.id))
    }
  }

  const renderRoleSwitch = (portalRoleModel: PortalRoleModel) => {
    return (
      <Switch
        id={portalRoleModel.id}
        key={portalRoleModel.id}
        checked={portalRoleIds.includes(portalRoleModel.id)}
        label={portalRoleModel.name}
        onChange={() => handleRoleChange(portalRoleModel, !portalRoleIds.includes(portalRoleModel.id))}
      />
    )
  }

  return (
    <Modal
      title={title}
      visible={visible}
      okButtonDisabled={!isSaveable()}
      okButtonTitle={t('save', {ns: 'common'})}
      cancelButtonTitle={t('cancel', {ns: 'common'})}
      onHide={() => handleOnCancel()}
      onOkClick={() => handleOnSave()}
      onCancelClick={() => handleOnCancel()}
    >
       <div className={styles.row}>
        <InputField
          disabled={isEditing}
          mandatory={!isEditing}
          id='identificationNumber'
          value={initialValue?.identificationNumber}
          onChange={(value) => setIdentificationNumber(value)}
          label={t('identificationNumber', {ns: 'common'})}
          placeholder=''
        />
      </div>
      <div className={styles.row}>
        <InputField
          disabled={isEditing}
          mandatory={!isEditing}
          id='email'
          value={initialValue?.email}
          onChange={(value) => setEmail(value)}
          label={t('email', {ns: 'common'})}
          placeholder=''
        />
      </div>

      <div className={styles.row}>
        <h5 style={{marginTop: 20, marginBottom: 10}}>{t('roles', {ns: 'portalUsers'})}</h5>
        {portalRoles.map(x => renderRoleSwitch(x))}
      </div>

      <div className={styles.row}>
        <h5 style={{marginTop: 20, marginBottom: 10}}>{t('contact', {ns: 'portalUsers'})}</h5>
        <Switch
          id='isContactTechnical'
          checked={isContactTechnical}
          label={t('contactTechnical', {ns: 'portalUsers'})}
          onChange={() => setIsContactTechnical(!isContactTechnical)}
          tooltipText={t('contactTechnicalDescription', {ns: 'portalUsers'})}
        />
        <Switch
          id='isContactFinance'
          checked={isContactFinance}
          label={t('contactFinance', {ns: 'portalUsers'})}
          onChange={() => setIsContactFinance(!isContactFinance)}
          tooltipText={t('contactFinanceDescription', {ns: 'portalUsers'})}
        />
        <Switch
          id='isContactPayroll'
          checked={isContactPayroll}
          label={t('contactPayroll', {ns: 'portalUsers'})}
          onChange={() => setIsContactPayroll(!isContactPayroll)}
          tooltipText={t('contactPayrollDescription', {ns: 'portalUsers'})}
        />
      </div>
    </Modal>
  )
}

export default EditEmployerPortalUserModal

const useStyles = createUseStyles({
  row: {
    paddingBottom: 12
  }
})
