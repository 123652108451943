import { useEffect, useState } from 'react'
import { useRoutes } from 'react-router'
import { useLocation } from 'react-router-dom'
import './App.css'
import { useAppDispatch, useAppSelector } from './redux/hooks'
import appRoutes from './navigation/Routes'
import useAuthUserActions from './hooks/auth/useAuthUserActions'
import FullScreenLoadingIndicator from './components/FullScreenLoadingIndicator'
import { Breakpoints } from './constants/breakpoints'
import { sidebarStateToggle } from './redux/slices/appStateSlice'

const App = () => {
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated)
  const user = useAppSelector(state => state.auth.authenticatedUser)
  const currentEmployerId = useAppSelector(state => state.auth.currentEmployerId)

  const dispatch = useAppDispatch()
  const location = useLocation()
  const routes = useRoutes(appRoutes(isAuthenticated, user, currentEmployerId, location))
  const { initialAuthenticatedUserRefetch } = useAuthUserActions()
  const [initialAuthRefreshTryDone, setInitialAuthRefreshTryDone] = useState<boolean>(false)

  useEffect(() => {
    setInitialSidebarState()

    if (!isAuthenticated && localStorage.getItem('authToken')) {
      const refetchAuth = (async () => {
        await initialAuthenticatedUserRefetch()
        setInitialAuthRefreshTryDone(true)
      })

      refetchAuth()
    } else {
      setInitialAuthRefreshTryDone(true)
    }
  }, [])

  const setInitialSidebarState = () => {
    if (window.innerWidth < Breakpoints.tablet) {
      dispatch(sidebarStateToggle())
    }
  }

  return ( !initialAuthRefreshTryDone ? <FullScreenLoadingIndicator visible={true} /> :  routes)
}

export default App
