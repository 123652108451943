import { apiConfig } from "../apiConfig"
import useFetchClient from "./useFetchClient"
import { EmployerPortalUser, CreateEmployerPortalUserModel, UpdateEmployerPortalUserModel } from "../redux/types";
import { useAppDispatch } from "../redux/hooks";
import {
    loading,
    portalUserCreated,
    portalUserCreateFailure,
    portalUserDeleted,
    portalUserDeleteFailure,
    portalUsersFetched,
    portalUsersFetchFailure,
    portalUserUpdated,
    portalUserUpdateFailure
} from "../redux/slices/portalUserSlice";

const usePortalUserActions = () => {
    const fetchClient = useFetchClient()
    const dispatch = useAppDispatch()

    const getUsers = () => {
        dispatch(loading())

        return fetchClient.get(apiConfig.routes.users)
            .then(response => {
                dispatch(portalUsersFetched(response.data as EmployerPortalUser[]))
            })
            .catch(error => {
                console.log('getUsers error:', error)
                dispatch(portalUsersFetchFailure(error))
            })
    }

    const createUser = (user: CreateEmployerPortalUserModel) => {
        dispatch(loading())

        return fetchClient.post(apiConfig.routes.users, user)
            .then(response => {
                dispatch(portalUserCreated(response.data as EmployerPortalUser))
            })
            .catch(error => {
                console.log('createUser error:', error)
                dispatch(portalUserCreateFailure(error))
            })
    }

    const updateUser = (portalUserId: string, user: UpdateEmployerPortalUserModel) => {
        dispatch(loading())
        const url = `${apiConfig.routes.users}${portalUserId}`

        return fetchClient.post(url, user)
            .then(response => {
                dispatch(portalUserUpdated(response.data as EmployerPortalUser))
            })
            .catch(error => {
                console.log('updateUser error:', error)
                dispatch(portalUserUpdateFailure(error))
            })
    }

    const deleteUser = (user: EmployerPortalUser) => {
        dispatch(loading())
        const url = `${apiConfig.routes.users}${user.portalUserId}`

        return fetchClient.delete(url)
            .then(response => {
                dispatch(portalUserDeleted(user))
            })
            .catch(error => {
                console.log('deleteUser error:', error)
                dispatch(portalUserDeleteFailure(error))
            })
    }

    return {
        getUsers,
        createUser,
        updateUser,
        deleteUser
    }
}

export default usePortalUserActions
