import { faBars, faIndustry, faSignOutAlt, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { createUseStyles } from 'react-jss'
import { Colors } from '../../constants/colors'
import useAuthUserActions from '../../hooks/auth/useAuthUserActions'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { sidebarStateToggle } from '../../redux/slices/appStateSlice'
import LanguagePicker from '../LanguagePicker'
import { useTranslation } from 'react-i18next'
import MyProfileModal from '../MyProfileModal'
import { useEffect, useState } from 'react'
import EmployerSelectorModal from '../EmployerSelectorModal'
import { loginTasksHandled } from '../../redux/slices/authUserSlice'
import Emitter from '../../eventing/Emitter'
import { Events } from '../../eventing/Events'

const Header = () => {
    const styles = useStyles()
    const { logout } = useAuthUserActions()
    const dispatch = useAppDispatch()
    const { t } = useTranslation('common');

    const [myProfileModalVisible, setMyProfileModalVisible] = useState<boolean>(false)
    const [employerSelectorModalVisible, setEmployerSelectorModalVisible] = useState<boolean>(false)

    const authenticatedUser = useAppSelector(state => state.auth.authenticatedUser)
    const regularLoginHandled = useAppSelector(state => state.auth.regularLoginTasksHandled)

    useEffect(() => {
        if (!regularLoginHandled)
        {
            if ((authenticatedUser?.givenName ?? '').length === 0 ||
                (authenticatedUser?.familyName ?? '').length === 0 ||
                (authenticatedUser?.phoneNumber ?? '').length === 0) {
                setMyProfileModalVisible(true)
            }
        }
    }, [regularLoginHandled])

    useEffect(() => {
        Emitter.on(Events.displayProfile, () => setMyProfileModalVisible(true))
        return () => {
          Emitter.off(Events.displayProfile)
        }
    })

    const handleMyProfileClosed = () => {
        setMyProfileModalVisible(false)

        if (!regularLoginHandled) {
            dispatch(loginTasksHandled())
        }
    }

    return (
        <div className={styles.container}>
            <div onClick={() => dispatch(sidebarStateToggle())}>
                <FontAwesomeIcon
                        icon={faBars}
                        color={Colors.gray1}
                />
            </div>
            <div className={styles.rightContainer}>
                {(authenticatedUser?.employers.length ?? 0) > 1 &&
                <div onClick={() => setEmployerSelectorModalVisible(true)} style={{cursor: 'pointer', marginTop: 2}}>
                    <FontAwesomeIcon
                        icon={faIndustry}
                        color={Colors.gray1}
                        size='lg'
                        title={t('selectEmployer')}
                    />
                </div>
                }
                <div onClick={() => setMyProfileModalVisible(true)} style={{cursor: 'pointer', marginTop: 2}}>
                    <FontAwesomeIcon
                        icon={faUser}
                        color={Colors.gray1}
                        size='lg'
                        title={t('myProfile')}
                    />
                </div>
                <div>
                    <LanguagePicker />
                </div>
                <div onClick={logout} style={{cursor: 'pointer', marginTop: 2}}>
                    <FontAwesomeIcon
                        icon={faSignOutAlt}
                        color={Colors.gray1}
                        size='lg'
                        title={t('signOut')}
                    />
                </div>
            </div>
            <MyProfileModal visible={myProfileModalVisible} onCloseClick={handleMyProfileClosed} />
            <EmployerSelectorModal visible={employerSelectorModalVisible} onCloseClick={() => setEmployerSelectorModalVisible(false)} />
        </div>
    )
}

export default Header

const useStyles = createUseStyles({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingLeft: 30,
        paddingRight: 20,
        backgroundColor: Colors.grayBackgroundApp,
    },
    rightContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
        columnGap: 30
    }
})
