import { CurrentUser, PortalRoleModel } from "../redux/types";

export const hasAnyRequiredPolicy = (user: CurrentUser | null, currentEmployerId: string | null, ...requiredPolicies: (keyof PortalRoleModel)[]) => {
    if (!user || !currentEmployerId) {
        return false
    }

    if (requiredPolicies.length === 0) {
        return true
    }

    const employeePortalUser = user.employers.find(x => x.employerId === currentEmployerId)
    if (!employeePortalUser) {
        return false
    }

    if (employeePortalUser.isOwner) {
        return true
    }

    return requiredPolicies.some(policy =>
      employeePortalUser.portalRoles.some(role => role[policy])
    );
}

export const userHasAuthorization = (user: CurrentUser, currentEmployerId: string, authorizationPredicate: (role: PortalRoleModel) => boolean) => {
    const employeePortalUser = user.employers.find(x => x.employerId === currentEmployerId)
    if (!employeePortalUser) {
        return false
    }

    if (employeePortalUser.isOwner) {
        return true
    }

    return employeePortalUser.portalRoles.some(authorizationPredicate)
}
