import {apiConfig} from "../../apiConfig"
import {useAppDispatch} from "../../redux/hooks"
import useFetchClient from "../useFetchClient"
import {
  authenticationFailure,
  authenticationSuccess,
  loading,
  logout as logoutAction
} from "../../redux/slices/authUserSlice";
import {useNavigate} from "react-router";
import {AuthenticationData} from "../../redux/types";
import {useTranslation} from "react-i18next";

const useAuthUserActions = () => {
  const url = `${apiConfig.routes.auth}`
  const fetchClient = useFetchClient()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const {i18n} = useTranslation();

  const login = (email: string, password: string, redirectTo?: string) => {
    dispatch(loading())

    return fetchClient.post(url, {email: email, password: password})
      .then(response => {
        const authResult = response.data as AuthenticationData

        localStorage.setItem('authToken', authResult.accessToken)
        dispatch(authenticationSuccess({regularLogin: true, data: authResult}))
        i18n.changeLanguage(authResult.user.language)

        navigate(redirectTo ? redirectTo : '/')
      })
      .catch(error => {
        console.log('Fetch error:', error)
        dispatch(authenticationFailure(error))
      })
  }

  const exchange = async (token: string, redirectTo?: string) => {
    dispatch(loading())

    return await fetch(url + "exchange", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `bearer ${token}`
      }
    }).then(response => {
      if (!response.ok) {
        throw Error('Failed to exchange token')
      }
      return response.json()
    })
      .then(data => {
        const authResult = data as AuthenticationData

        localStorage.setItem('authToken', authResult.accessToken)
        dispatch(authenticationSuccess({regularLogin: false, data: authResult}))
        i18n.changeLanguage(authResult.user.language)

        navigate(redirectTo ? redirectTo : '/')
      })
      .catch(error => {
        const errorText = error instanceof Error ? error.message : error.toString()
        dispatch(authenticationFailure(errorText))
      })
  }

  const initializeCappyAuthentication = (token: string) => {
    dispatch(loading())

    return fetchClient.post(url + "initializeCappyAuthentication", {token: token})
      .then(response => {
        const authResult = response.data as AuthenticationData

        localStorage.setItem('authToken', authResult.accessToken)
        dispatch(authenticationSuccess({regularLogin: false, data: authResult}))
        i18n.changeLanguage(authResult.user.language)
      })
      .catch(error => {
        console.log('initializeCappyAuthentication error:', error)
        dispatch(authenticationFailure(error))
      })
  }

  const switchEmployer = (employerId: string) => {
    dispatch(loading())

    return fetchClient.post(url + `selectedemployer/${employerId}`)
      .then(response => {
        const authResult = response.data as AuthenticationData

        localStorage.setItem('authToken', authResult.accessToken)
        dispatch(authenticationSuccess({regularLogin: false, data: authResult}))

        window.location.reload();
      })
      .catch(error => {
        console.log('switchEmployer error:', error)
        dispatch(authenticationFailure(error))
      })
  }

  const initialAuthenticatedUserRefetch = () => {
    dispatch(loading())

    return fetchClient.get(url)
      .then(response => {
        const authResult = response.data as AuthenticationData
        localStorage.setItem('authToken', authResult.accessToken) // refresh token
        dispatch(authenticationSuccess({regularLogin: false, data: authResult}))
      })
      .catch(error => {
        console.log('refetchAuthenticatedUser error:', error)
        localStorage.removeItem('authToken')
        dispatch(authenticationFailure(null))
      })
  }

  const logout = () => {
    console.log('Logging out')
    dispatch(logoutAction())
    navigate('/login')
    localStorage.removeItem('authToken')
  }

  return {
    login,
    initialAuthenticatedUserRefetch,
    switchEmployer,
    logout,
    exchange,
    initializeCappyAuthentication
  }
}

export default useAuthUserActions
