import {createUseStyles} from 'react-jss'
import {useTranslation} from 'react-i18next'
import Button from "../Button";
import React, {useEffect, useState} from "react";
import {useAppSelector} from "../../redux/hooks";
import {Modal} from "react-bootstrap";
import QrBankIdLogin from "./QrBankIdLogin";
import useAuthUserActions from "../../hooks/auth/useAuthUserActions";
import {AuthenticationMethod} from "../../authentication/AuthenticationMethod";
import BankIdLogin from "./BankIdLogin";
import {AuthenticationMethodSelector} from "../AuthenticationMethodSelector";
import TextButton from "../TextButton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faLock} from "@fortawesome/free-solid-svg-icons";
import {Colors} from "../../constants/colors";
import {PortalUserAuthenticationScheme} from "../../redux/types";


const InitializeCappyAuthModal = () => {
  const styles = useStyles()
  const {t} = useTranslation('login');
  const [visible, setVisible] = useState<boolean>(false)

  const {initializeCappyAuthentication} = useAuthUserActions()

  const [modalState, setModalState] = useState<'init' | AuthenticationMethod | 'done'>()

  const user = useAppSelector(state => state.auth.authenticatedUser)
  const currentEmployerId = useAppSelector(state => state.auth.currentEmployerId)
  const employer = useAppSelector(state => state.employer.employer)

  useEffect(() => {
    if (!user || !employer) {
      return setVisible(false)
    }

    if (employer.id !== currentEmployerId) {
      //Happens when user switches employer
      return setVisible(false)
    }

    if (visible && user.authScheme === PortalUserAuthenticationScheme.Cappy) {
      return setModalState('done')
    }

    if (employer.defaultPortalUserAuthenticationScheme === PortalUserAuthenticationScheme.Cappy &&
      user.authScheme !== PortalUserAuthenticationScheme.Cappy) {
      return setVisible(true)
    }

    return setVisible(false)
  }, [user, employer, currentEmployerId]);


  const renderContent = () => {
    switch (modalState) {
      case AuthenticationMethod.BankIdQr:
        return (
          <div className={styles.container}>
            <QrBankIdLogin onAuthenticated={initializeCappyAuthentication}/>
            <div className={styles.footer}>
              <TextButton onClick={() => {setModalState('init')}}>{t('back')}</TextButton>
            </div>
          </div>
        )
      case AuthenticationMethod.BankId:
        return (
          <div className={styles.container}>
            <BankIdLogin onAuthenticated={initializeCappyAuthentication}/>
            <div className={styles.footer}>
              <TextButton onClick={() => {setModalState('init')}}>{t('back')}</TextButton>
            </div>
          </div>
        )
      case 'done':
        return (
          <div className={styles.container}>
            <div className={styles.iconAndText}>
              <FontAwesomeIcon icon={faCheck} size='4x' color={Colors.brandGreen}/>
              <p>{t("initializeCappyAuthDone")}</p>
            </div>
            <Button title={t("close")} onClick={() => {setVisible(false)}}/>
          </div>
        )
      default:
        return (
          <div className={styles.container}>
            <div className={styles.iconAndText}>
              <FontAwesomeIcon icon={faLock} size='4x' color={Colors.brandGreen}/>
              <p>{t('initializeCappyAuthDescription')}</p>
            </div>
            <AuthenticationMethodSelector onAuthenticationMethodSelected={setModalState}/>
            <div className={styles.footer}>
              <TextButton onClick={() => {
                setVisible(false)
              }}>{t('anotherTime')}</TextButton>
            </div>
          </div>
        )
    }
  }

  return (
    <Modal size={"lg"} fullscreen={"md-down"} show={visible} scrollable={true}>
      <Modal.Header>
        <Modal.Title>{t('initializeCappyAuthTitle')}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderContent()}
      </Modal.Body>
    </Modal>
  )
}

export default InitializeCappyAuthModal

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    padding: 10,
    flex: 1
  },
  iconAndText: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
    padding: 20,
    textAlign: 'center'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})
